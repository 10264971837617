<template>
  <v-main class="pt-3 pl-10 pr-10 pb-7">
    <MenuReport/>
    <!-- <PaymentComponent /> -->
    <router-view></router-view>
  </v-main>
</template>

<script>
import MenuReport from './components/Menu'
export default {
  name: 'ReportContainerComponent',
  components: {
    MenuReport
  },
  data: () => {
    return {};
  },
  methods: {},
};
</script>
