<template>
  <div class="d-block">
    <v-tabs
      class="w-100"
      v-model="tabRoute"
      align-with-title
      >
      <v-tab
        class="ml-0"
        exact
        v-for="(itemMenu, index) in listMenuItem"
        :key="index"
        :to="itemMenu.path"
        link
        >
        {{ itemMenu.title }}
      </v-tab>
    </v-tabs>
  </div>
</template>

<script>
const listMenuItem = [
  {
    title: 'Daily payment report',
    namePath: 'reportByDepartment', // same router name
    path: '/report/common-report'
  },
  {
    title: 'Detail Order Payment Report',
    namePath: 'paymentReportByDateAndClinic',
    path: '/report/payment-report-date-and-clinic'
  }
]

export default {
  name: 'ReportMenuComponent',
  data: () => {
    return {
      listMenuItem,
      tabRoute: null
    };
  },
  methods: {},
};
</script>
